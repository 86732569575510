import ReactHtmlParser from "react-html-parser";
import { graphql, navigate, PageProps } from "gatsby";
import { Column, Row } from "hedron";
import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import Layout from "../components/Layout";
import PageContent from "../components/PageContent";
import PostCover from "../components/PostCover";
import SEO from "../components/seo";
import SideBar from "../components/SideBar";
import { IWordpressPost } from "../types/wordpressPost";
import ReactPaginate from "react-paginate";
import { PaginateStyles } from "../components/PaginateStyles";
import { NewsColumn } from "../components/ContentColumn";
import { IJurassicOutpostSettings } from "../types/settings";
import MainAndSidebarWrapper from "../components/MainAndSidebarWrapper";

interface IPageContext {
  skip: number;
  limit: number;
  current: number;
  name: string;
  slug: string;
  total: number;
}

interface ISettings {
  jurassicOutpostSettings: Pick<
    IJurassicOutpostSettings,
    "metadataTags" | "metadataDescription"
  >;
}
interface IData {
  allWp: {
    nodes: ISettings[];
  };
  allWpPost: {
    nodes: IWordpressPost[];
  };
}

interface Props extends PageProps {
  data: IData;
  pageContext: IPageContext;
}

const IndexPage: React.FC<Props> = ({ data, pageContext, location }) => {
  const siteOptions = data.allWp.nodes[0].jurassicOutpostSettings;
  const posts = data.allWpPost.nodes;
  if (siteOptions) {
    const siteTags = siteOptions.metadataTags.replace(/(<([^>]+)>)/gi, "");

    return (
      <Layout location={location}>
        <>
          <SEO
            title="Home"
            keywords={siteTags.split(", ")}
            description={siteOptions.metadataDescription}
          />
          <MainAndSidebarWrapper>
            <Column md={3}>
              <SideBar />
            </Column>
            <NewsColumn md={9}>
              <Breadcrumb title={ReactHtmlParser(pageContext.name)} />
              <PageContent>
                <>
                  {posts &&
                    posts.map((post) => {
                      const article = post;
                      return (
                        <PostCover
                          id={article.databaseId}
                          key={post.id}
                          author={{
                            url: article.author.node.url,
                            name: article.author.node.name,
                            customAvatar: article.author.node.customAvatar,
                          }}
                          date={article.date}
                          slug={article.slug}
                          content={article.content}
                          title={article.title}
                          excerpt={article.excerpt}
                          featuredImage={article.featuredImage}
                        />
                      );
                    })}
                  <PaginateStyles>
                    <ReactPaginate
                      pageCount={pageContext.total / 6}
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={2}
                      initialPage={pageContext.current}
                      hrefBuilder={(pageIndex) =>
                        pageIndex === 1
                          ? `/category${pageContext.slug}`
                          : `/category${pageContext.slug}/${pageIndex}`
                      }
                      disableInitialCallback={true}
                      onPageChange={(sel) =>
                        navigate(
                          sel.selected === 0
                            ? "/"
                            : `/category${pageContext.slug}/${sel.selected + 1}`
                        )
                      }
                    />
                  </PaginateStyles>
                </>
              </PageContent>
            </NewsColumn>
          </MainAndSidebarWrapper>
        </>
      </Layout>
    );
  } else {
    return null;
  }
};

export default IndexPage;

export const query = graphql`
  query categoryQuery($name: String!, $skip: Int = 0, $limit: Int = 6) {
    allWp {
      nodes {
        jurassicOutpostSettings {
          metadataTags
          metadataDescription
        }
      }
    }
    allWpPost(
      limit: $limit
      skip: $skip
      sort: { fields: date, order: DESC }
      filter: { categories: { nodes: { elemMatch: { name: { eq: $name } } } } }
    ) {
      nodes {
        ...getWordpressPostContent
      }
    }
  }
`;
